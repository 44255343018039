<template>
  <base-page-layout title="Mercatrade">
    <p class="header-filter-title">Filtros</p>
    <div class="header-filter">
      <div class="header-filter-selects">
        <header-filter v-model="filters" :filters-to-show="['campaignDates', 'status', 'smsDates', 'proposalDeadline', 'industry']" type="mercatrade" />
      </div>

      <div>
        <v-menu offset-y open-on-hover>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mr-2" color="primary" outlined v-bind="attrs" v-on="on">
              <img width="12" height="12" class="mr-2" :src="require('@/assets/icons/airflow.png')" alt="airflow" />Dags
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="openDag()">
              <v-list-item-content>Inserção</v-list-item-content>
            </v-list-item>
            <v-list-item @click="openDagDelete()">
              <v-list-item-content>Deleção</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <mf-button color="primary" label="Cadastro" icon="add" @click="create"></mf-button>
      </div>
    </div>

    <mf-card-container v-model="search" placeholder="Busca" use-search title="Campanhas importadas" description="Lista das campanhas mercatrade">
      <template>
        <div class="mt-2">
          <v-btn outlined color="primary" :loading="loading" @click="editTable">
            <mf-icon class="mr-2" color="primary" :icon="editTableIcon" size="24" />
            {{ editTableLabel }}
          </v-btn>

          <mf-button
            v-if="showTableSelection"
            outlined
            color="error"
            :label="tableSelectionText"
            icon="delete"
            iconColor="primary"
            iconPosition="left"
            class="ml-2"
            :disabled="!selectedOffers.length"
            :loading="loading"
            @click="deleteSelectedOffers"
          ></mf-button>
        </div>
      </template>

      <template v-slot:headers>
        <div class="d-flex align-center" style="gap: 8px">
          <v-btn icon color="primary" @click="refreshTable"><v-icon>refresh</v-icon></v-btn>
          <export-trade-error class="mr-3" trade-type="MERCATRADE" />
        </div>
      </template>

      <v-data-table
        v-model="selectedOffers"
        :loading="$apollo.queries.offers.loading"
        :headers="headers"
        :items="offers"
        :search="debounceSearch"
        :options.sync="options"
        :server-items-length="dataCount"
        :footer-props="{ itemsPerPageOptions: [10, 50, 100] }"
        :show-select="showTableSelection"
        item-key="_id"
        selectable-key="can_be_selected"
      >
        <template v-slot:item.title="{ item }" class="truncate-name">
          <tooltip align="top" fit-content :message="item.title">
            {{ formatCampaignTitle(item.title) | truncate({ length: 40 }) }}
          </tooltip>
        </template>

        <template v-slot:item.retailers.name="{ item }" class="truncate-name">
          <tooltip align="top" fit-content :message="item.retailers.name">
            {{ item.retailers.name | truncate({ length: 36 }) }}
          </tooltip>
        </template>

        <template v-slot:item.processed="{ item }">
          <v-icon class="mr-2" x-small :color="getStatus(item).color">
            mdi-brightness-1
          </v-icon>
          {{ getStatus(item).text }}
        </template>

        <template v-slot:item.retailers.discounts.start_date="{ item }">
          {{ item.retailers.discounts.start_date | date('dd/MM/yyyy') }}
        </template>

        <template v-slot:item.retailers.discounts.end_date="{ item }">
          {{ item.retailers.discounts.end_date | date('dd/MM/yyyy') }}
        </template>

        <template v-slot:item.sms_date_time="{ item }">
          {{ item.campaigns.sms.send_date | date('dd/MM/yyyy') }} {{ checkSmsDateTime(item) }} {{ item.campaigns.sms.send_time }}
        </template>

        <template v-slot:item.industry.proposal_deadline="{ item }">
          {{ item.industry.proposal_deadline | date('dd/MM/yyyy HH:mm') }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-menu :close-on-content-click="true">
            <template #activator="{ on }">
              <v-btn class="ml-2" icon v-on="on">
                <mf-icon>more_vert</mf-icon>
              </v-btn>
            </template>
            <v-list>
              <error-log-dialog :item="item" />
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </mf-card-container>
  </base-page-layout>
</template>

<script>
import ErrorLogDialog from '@/modules/industry/components/ErrorLogDialog.vue'
import { QUERY_GET_MERCATRADE_OFFERS_PAGINATED, MUTATION_DELETE_INDUSTRY_OFFERS } from '@/modules/industry/graphql'

export default {
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout.vue'),
    Tooltip: () => import('@/components/base/Tooltip.vue'),
    ExportTradeError: () => import('@/modules/industry/components/ExportTradeError.vue'),
    HeaderFilter: () => import('@/modules/industry/components/HeaderFilter.vue'),
    ErrorLogDialog
  },
  data: () => ({
    loading: false,
    search: '',
    debounceSearch: '',
    options: {
      itemsPerPage: 50
    },
    offers: [],
    timer: null,
    dataCount: 0,
    filters: {
      dates: [],
      status: [],
      offerTypes: [],
      industries: [],
      smsDates: [],
      proposalDeadline: []
    },
    showTableSelection: false,
    selectedOffers: []
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Status',
          value: 'processed',
          sortable: false,
          width: '130px'
        },
        {
          text: 'Campanha',
          value: 'title',
          sortable: true,
          filterable: true,
          width: '20%'
        },
        {
          text: 'Varejista',
          value: 'retailers.name',
          sortable: true,
          filterable: true
        },
        {
          text: 'DB Name',
          value: 'db_name',
          sortable: true,
          filterable: true
        },
        {
          text: 'Data início',
          value: 'retailers.discounts.start_date',
          sortable: false
        },
        {
          text: 'Data fim',
          value: 'retailers.discounts.end_date',
          sortable: false
        },
        {
          text: 'Data SMS',
          value: 'sms_date_time',
          sortable: false
        },
        {
          text: 'Prazo de adesão',
          value: 'industry.proposal_deadline',
          sortable: false
        },
        {
          text: 'Indústria',
          value: 'industry.name',
          sortable: true
        },
        {
          text: 'Segmentos',
          value: 'retailers.segmentation'
        },
        {
          text: '',
          value: 'actions',
          align: 'center',
          sortable: false,
          width: '2%'
        }
      ]
    },
    editTableLabel() {
      return this.showTableSelection ? 'Cancelar seleção' : 'Selecionar'
    },
    editTableIcon() {
      return this.showTableSelection ? 'close' : 'edit'
    },
    tableSelectionText() {
      if (this.selectedOffers.length === 1) {
        return `Remover ${this.selectedOffers.length} selecionada`
      } else {
        return `Remover ${this.selectedOffers.length} selecionadas`
      }
    },
    selectedIndustryIds() {
      return this.filters.industries.map(industry => industry._id)
    }
  },
  apollo: {
    offers: {
      query: QUERY_GET_MERCATRADE_OFFERS_PAGINATED,
      fetchPolicy: 'network-only',
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['accounts']
        }
      },
      variables() {
        return {
          input: {
            start_date: this.filters.dates[0] || '',
            end_date: this.filters.dates[1] || '',
            proposal_deadline_start_date: this.filters.proposalDeadline[0] || '',
            proposal_deadline_end_date: this.filters.proposalDeadline[1] || '',
            sms_send_start_date: this.filters.smsDates[0] || '',
            sms_send_end_date: this.filters.smsDates[1] || '',
            status: this.filters.status,
            industry_ids: this.selectedIndustryIds
          },
          pagination: {
            page: this.options.page || 1,
            pageSize: this.options.itemsPerPage || 50,
            search: this.debounceSearch || null,
            sort: this.options.sortBy || ['start_date'],
            sortDirection: this.options.sortDesc && this.options.sortDesc.map(direction => (direction ? 'DESC' : 'ASC'))
          }
        }
      },
      update(result) {
        this.dataCount = result.getMercatradeOffers.count
        const data = result.getMercatradeOffers.data.map(offer => ({
          ...offer,
          can_be_selected: this.canBeSelected(offer)
        }))
        return data
      }
    }
  },
  watch: {
    search(newValue, _) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.options.page = 1
        this.debounceSearch = newValue
      }, 300)
    },
    filters: {
      handler: function() {
        this.options.page = 1
        window.localStorage.setItem('filters-mercatrade-industry', JSON.stringify(this.filters))
      },
      deep: true
    }
  },
  beforeMount() {
    const savedFilters = window.localStorage.getItem('filters-mercatrade-industry')
    if (savedFilters) {
      const parsed = JSON.parse(savedFilters)
      this.filters.dates = parsed.dates ? parsed.dates : []
      this.filters.status = parsed.status ? parsed.status : []
      this.filters.offerTypes = parsed.offerTypes ? parsed.offerTypes : []
      this.filters.industries = parsed.industries ? parsed.industries : []
      this.filters.smsDates = parsed.smsDates ? parsed.smsDates : []
      this.filters.proposalDeadline = parsed.proposalDeadline ? parsed.proposalDeadline : []
    }
  },
  methods: {
    create() {
      this.$router.push(`/mercatrade/create`)
    },

    getStatus(item) {
      let status = {}
      if (item.deleted === true) {
        status = {
          text: 'Removido',
          color: '#575a5e'
        }
      } else if (item.to_be_deleted === true) {
        status = {
          text: 'A ser removido',
          color: '#f6e27f'
        }
      } else if (item.processed === false || item.processed == null) {
        status = {
          text: 'Em processamento',
          color: '#339af0'
        }
      } else if (item.errors && Array.isArray(item.errors)) {
        status = {
          text: 'Erro',
          color: '#ff6b6b'
        }
      } else {
        status = {
          text: 'Processado',
          color: '#20c997'
        }
      }
      return status
    },

    checkSmsDateTime(item) {
      return item.campaigns.sms.send_date && item.campaigns.sms.send_time ? '|' : '-'
    },

    debounce(func, timeout = 300) {
      let timer
      return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
          func.apply(this, args)
        }, timeout)
      }
    },

    formatCampaignTitle(title) {
      let newTitle = title.replace('Campanha Trade AmBev (', ' ')
      const index = newTitle.indexOf(')')
      if (index > 0) {
        newTitle = newTitle.substring(0, index)
      }
      return newTitle
    },
    openDagDelete() {
      return window.open('https://gcp-airflow-static-dags.mercafacil.com/dags/delete_industry_offers/grid?search=delete_industry_offers', '_blank')
    },
    openDag() {
      return window.open('https://gcp-airflow-static-dags.mercafacil.com/dags/mercatrade_insert/grid?search=mercatrade_insert', '_blank')
    },
    editTable() {
      this.selectedOffers = []
      this.showTableSelection = !this.showTableSelection
    },
    async deleteSelectedOffers() {
      const offerIds = this.selectedOffers.map(offer => offer._id)

      let confirmMessage = `Deseja realmente excluir as ${offerIds.length} campanhas selecionadas?`
      if (offerIds.length === 1) {
        confirmMessage = `Deseja realmente excluir a campanha selecionada?`
      }
      const confirmation = await this.$confirm({
        title: 'Confirmar exclusão',
        message: confirmMessage,
        confirmText: 'Excluir',
        cancelText: 'Cancelar'
      })
        .then(() => true)
        .catch(() => false)
      if (!confirmation) {
        this.loading = false
        return
      }

      this.loading = true
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_DELETE_INDUSTRY_OFFERS,
          variables: {
            input: { modality: 'mercatrade', offer_ids: offerIds }
          },
          context: {
            uri: this.$microservicesUrls['accounts'],
            headers: {
              authorization: localStorage.getItem('session_id'),
              accountId: this.$route.params.id
            }
          }
        })
        this.$alert({
          alert_message: 'Campanhas marcadas para serem excluídas com sucesso',
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
        this.$apollo.queries.offers.refetch()
      } catch (error) {
        this.$alert({
          alert_message: 'Erro ao marcar campanhas para serem excluídas',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle',
          action: () => {
            const { errors } = error.networkError.result
            this.$errorDialog({
              title: errors.length > 1 ? 'Detalhes dos erros' : 'Detalhes do erro',
              errors: errors
            })
          },
          action_label: 'Ver detalhes'
        })
      } finally {
        this.showTableSelection = false
        this.loading = false
      }
    },
    canBeSelected(offer) {
      if (offer.deleted || offer.to_be_deleted) {
        return false
      }
      return true
    },
    refreshTable() {
      this.$apollo.queries.offers.refetch()
    }
  }
}
</script>

<style lang="scss" scoped>
.header-filter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  margin-right: 8px;
  gap: 12px;
}

.header-filter-title {
  margin-bottom: 6px !important;
  font-size: 18px;
  color: #0b2e67;
}

.header-filter-selects {
  display: flex;
  gap: 12px;
  align-items: center;
}

.header-filter-background {
  height: 56px;
  max-width: 220px;
  flex: 1;
}

@media screen and (max-width: 1366px) {
  .truncate-name {
    font-size: 12px !important;
  }
}
</style>
